/** CONVERSION MINDS **/
export class Partner {

    constructor(app) {
        this.app = app;
    }

    click(){
        console.log('click postback provider: 1702');
        this.sendClickOut();
    }

    click_dl(){
        console.log('click_dl postback provider: 1702');
        this.sendClickOut();
    }

    page_view(){
        console.log('page_view postback provider: 1702');
    }

    empty(){
        console.log('empty postback provider: 1702');
    }

    getFacebookPixel(){
        return null;
    }

    getTikTokPixel() {
        return null;
    }

    getTikTokToken() {
        return null;
    }


    sendClickOut(){
        const source = (/^.*?(?=_)/.exec(this.app.data.source) != null) ? /^.*?(?=_)/.exec(this.app.data.source)[0] : this.app.data.source;
        let url = null;
        switch(source.toLowerCase()){
            case 'facebook' : {
                url = `https://brain.intellagile.com/postback?source=33&event_type=ViewContent&pixel_id=416079732273486&token=${this.app.data.sub_id}`;
            }break;

            case 'taboola'       :
            case 'taboola1'      :
            case 'taboola2'      : {
                url = `https://brain.intellagile.com/postback?source=4&event_type=ClickButton&token=${this.app.data.sub_id}`;
            }break;

            default : break;
        }


        if(url !== null){
            fetch(url, {
                method: "GET",
                keepalive: true,
                mode: 'no-cors',
            }).catch(error => {
                console.error('Request failed', error);
            })
        }
    }
}
