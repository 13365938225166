/** AMETIST **/
export class Partner {

    constructor(app) {
        this.app = app;
        this.script_url = 'https://set.trecky.io/js/track_set.js';
    }

    async init() {
        return new Promise((resolve, reject) => {
            console.log('Partner 1610 init')
            if(this.script_url && this.script_url !== '') {
                const script = $(`script[src='${this.script_url}']`);
                if (script.length > 0 && typeof gnss_sendEvent == "undefined") {
                    $(script).on('load', () => {
                        resolve();
                    });
                } else {
                    $.getScript( this.script_url, function( data, textStatus, jqxhr ) {
                        resolve();
                    });
                }
            }
            else{
                resolve();
            }
        })
    }

    click(){
        console.log('click postback provider: 1610');
        if (typeof gnss_sendEvent !== "undefined") {
            this.sendClickEvent();
        }
        else{
            console.error('No partner script loaded');
        }
    }

    click_dl(){
        console.log('click_dl postback provider: 1610');
        if (typeof gnss_sendEvent !== "undefined") {
            this.sendClickEvent();
        }
        else{
            console.error('No partner script loaded');
        }
    }

    page_view(){
        console.log('page_view postback provider: 1610');
    }

    empty(){
        console.log('empty postback provider: 1610');
        if (typeof gnss_sendEvent !== "undefined") {
            const params = {
                message: 'No sponsored ads'
            };

            try {
                gnss_sendEvent('no_coverage', params);
            }
            catch (error) {
                console.error("Fail to send event 'no_coverage' to partner", error, params);
            }
        }
        else{
            console.error('No partner script loaded');
        }
    }

    getFacebookPixel(){

        switch(this.app.domain){
            case 'fb.pingoofy.com': return '1091429649174564';
            case 'fb.hodago.com'  : return '1811951422543988';
            default: return null;
        }

    }

    getTikTokPixel() {
        switch(this.app.domain){
            case 'tt.pingoofy.com': return 'CTNBHGBC77U1LI1DGIQG';
            case 'tt.hodago.com'  : return 'CTNBINBC77UFAAV35P10';
            default: return null;
        }
    }

    getTikTokToken() {
        switch(this.app.domain){
            case 'tt.pingoofy.com': return 'f8883618cd3630383e75498a7c7527769de39453';
            case 'tt.hodago.com'  : return '939b3b54f1a83c2d4f269b43d8ed547b36cd9ea8';
            default: return null;
        }
    }


    sendClickEvent() {

        try {
            const params = {
                host: this.app.config.domain,
                path: this.app.request.uri,
                click_id : this.app.data.sub_id,
            };

            gnss_sendEvent('click', params);
        }
        catch (error) {
            console.error("Fail to send event 'click' to partner", error, params);
        }

    }

}
