export default class PostbackManager {
    Partner;

    constructor(app) {
        console.log('PostbackManager constructor');
        this.Partner = null;
        this.app = app;
        this.init = null;
    }

    async initPartner(){

        if(this.init === null) {
            this.init = new Promise(async (resolve, reject) => {
                if (this.app.request.query.prt) {
                    try {
                        if (this.Partner == null) {
                            this.Partner = new (await import(/* webpackMode: "eager" */`./postback_partners/${this.app.request.query.prt}.js`)).Partner(this.app);
                            if (typeof this.Partner.init == 'function') {
                                await this.Partner.init();
                            }
                        }
                    } catch (error) {
                        console.error(`Partner config not found: ${this.app.request.query.prt}`, error);
                        this.Partner = null;
                    }
                    resolve( this.Partner !== null);
                }

                resolve(false);

            });
        }

        return this.init;
    }



    async click(){
        console.log("PostbackManager click");
        if(await this.initPartner()) {
            this.Partner.click();
        }
        this.sendConversion();
    }

    async click_dl(){
        console.log("PostbackManager click_dl");
        if(await this.initPartner()) {
            this.Partner.click_dl();
        }
        this.sendConversion();

    }

    async page_view(){
        console.log("PostbackManager page_view");
        if(await this.initPartner()) {
            this.Partner.page_view();
        }
    }

    async empty(){
        console.log("PostbackManager page_view");
        if(await this.initPartner()) {
            this.Partner.empty();
        }
    }

    sendConversion() {
        console.log("PostbackManager sendConversion");

        const source = (/^.*?(?=_)/.exec(this.app.data.source) != null) ? /^.*?(?=_)/.exec(this.app.data.source)[0] : this.app.data.source;

        switch(source.toLowerCase()){
            case 'tiktok'      :
                this.sendTikTokConversion(); break;
            case 'facebook'      :
                this.sendFacebookConversion(); break;

            case 'taboola'       :
            case 'taboola1'      :
            case 'taboola2'      :
                this.sendTaboolaConversion(); break;

            case 'outbrain'      :
                this.sendOutbrainConversion(); break;

            case 'mgid'          :
                this.sendMgidConversion(); break;

            case 'nativery' :
                this.sendNativeryConversion(); break;

            default : break;
        }
    }

    sendTikTokConversion(){
        console.log("PostbackManager sendTikTokConversion");
        const params = new URLSearchParams({
            track: JSON.stringify({
                ip: `${this.app.config.user.ip}`,
                user_agent: window.navigator.userAgent,
                date: new Date().toISOString(),
                click_id: `${this.app.data.sub_id}`,
                source_url: window.location.href,
                partner_id: this.app.request.query.prt ? this.app.request.query.prt : null,
                pixel: this.Partner && this.Partner.getTikTokPixel() ? this.Partner.getTikTokPixel() : this.app.config.partners?.source?.tiktok?.pixel,
                token: this.Partner && this.Partner.getTikTokToken() ? this.Partner.getTikTokToken() : this.app.config.partners?.source?.tiktok?.token,
                //token: this.app.config.tiktok?.source?.tiktok?.token,
                cookie: {
                    _ttp: `${this.app.request.cookies._ttp }`
                }
            }),
            platform: "tiktok"
        });

        const url = "https://keyword.intraprendenza.io/api/platformsService/sendEvent?" + params.toString();
        const headers = new Headers();
        headers.set("Authorization", "Basic aW50cmFwcmVuZGVuemE6c250NUNodjBzbnQ1Q2h2MA==");
        headers.set("Accept", "application/json");


        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: headers
        })
        .catch(error => {
            console.error('Request failed', error);
        });
    }

    sendFacebookConversion(){
        console.log("PostbackManager sendFacebookConversion");
        const params = new URLSearchParams({
            track: JSON.stringify({
                ip: `${this.app.config.user.ip}`,
                user_agent: window.navigator.userAgent,
                date: new Date().toISOString(),
                click_id: `${this.app.data.sub_id}`,
                source_url: window.location.href,
                partner_id: this.app.request.query.prt ? this.app.request.query.prt : null,
                pixel: this.Partner && this.Partner.getFacebookPixel() ? this.Partner.getFacebookPixel() : this.app.config.partners?.source?.facebook?.pixel,
                token: this.app.config.partners?.source?.facebook?.token,
                cookie: {
                    _fbc: `${this.app.request.cookies._fbc }`,
                    _fbp: `${this.app.request.cookies._fbp }`
                }
            }),
            platform: "facebook"
        });

        const url = "https://keyword.intraprendenza.io/api/platformsService/sendEvent?" + params.toString();
        const headers = new Headers();
        headers.set("Authorization", "Basic aW50cmFwcmVuZGVuemE6c250NUNodjBzbnQ1Q2h2MA==");
        headers.set("Accept", "application/json");


        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: headers
        })
        .catch(error => {
            console.error('Request failed', error);
        });
    }

    sendTaboolaConversion(){
        if(this.app.data.sub_id && this.app.data.sub_id !== '') {
            const url = `https://trc.taboola.com/actions-handler/log/3/s2s-action?click-id=${this.app.data.sub_id}&name=clickoutrt&revenue=&cy=EUR`;
            console.log('sendTaboolaConversion postback provider: main', url);
            fetch(url, {
                method: "GET",
                keepalive: true,
                mode: 'no-cors',
            }).catch(error => {
                console.error('Request failed', error);
            })
        }

    }

    sendOutbrainConversion(){
        if(this.app.data.sub_id && this.app.data.sub_id !== '') {
            const url = `https://tr.outbrain.com/pixel?ob_click_id=${this.app.data.sub_id}&name=clickoutrt&orderValue=&currency=EUR`;
            console.log('sendOutbrainConversion postback provider: main', url);
            fetch(url, {
                method: "GET",
                keepalive: true,
                mode: 'no-cors',
            }).catch(error => {
                console.error('Request failed', error);
            })
        }
    }

    sendMgidConversion(){
        if(this.app.data.sub_id && this.app.data.sub_id !== '') {
            const url = `https://a.mgid.com/postback?c=${this.app.data.sub_id}&r=&e=clickoutrt`;
            console.log('sendMgidConversion postback provider: main', url);
            fetch(url, {
                method: "GET",
                keepalive: true,
                mode: 'no-cors',
            }).catch(error => {
                console.error('Request failed', error);
            })
        }
    }

    sendNativeryConversion(){
        if(this.app.data.sub_id && this.app.data.sub_id !== '') {
            const url = `https://w.nativery.com/s2s-action?nat_click_id=${ this.app.data.sub_id }`;
            console.log('sendNativeryConversion postback provider: main', url);
            fetch(url, {
                method: "GET",
                keepalive: true,
                mode: 'no-cors',
            }).catch(error => {
                console.error('Request failed', error);
            })
        }
    }



}
