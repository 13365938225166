import PostbackManager from "./postback_manager";


class EventLogger {
    constructor(app) {
        this.event_endpoint = 'https://stats.intraprendenza.io/event';
        this.payload = {
            params: app.request,
            user_agent: window.navigator.userAgent,
            user_lang: window.navigator.language,
            referer: app.referer
        };
        this.postback_manager = new PostbackManager(app);
    }

    //PAGE VIEW
    log_view = () => {
        const data = {
            event_type : 'page_view',
        };

        fetch(this.event_endpoint, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...this.payload, ...data})
        })
        .then((resp) => resp.json())
        .then((body) => {
            //console.log(body);
        });

        //send postback to platform or partners
        this.postback_manager.page_view();
    }


    //CLICKOUT
    log_clickout = (black = false) => {
        const data = {
            event_type : 'clickout',
            dl : black
        };

        fetch(this.event_endpoint, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...this.payload, ...data})
        })
        .then((resp) => resp.json())
        .then((body) => {
            //console.log(body);
        });

        //send postback to platform and/or partners
        if(!black) {
            this.postback_manager.click();
        }
        else {
            this.postback_manager.click_dl();
        }

    }


    //KEYWORD RESULT
    log_keyword_result = (payload) => {
        const data = {
            event_type : 'keyword_result',
            ...payload
        };
        fetch(this.event_endpoint, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...this.payload, ...data})
        })
        .then((resp) => resp.json())
        .then((body) => {
            // console.log(body);
        });


        //send postback to platform or partners
        if(payload.empty) {
            this.postback_manager.empty();
        }

    }

}

console.log("EVENT LOGGER INIT");
window.event_logger = new EventLogger(app);
window.event_logger.log_view();


//CLICK LISTENER
$(document).on('click auxclick', '#boxTop a, #boxTop2 a, .shop a, .promoItem a', () => {
    window.event_logger.log_clickout();
});

$(document).on('click_dl', () => {
    console.log('Click DL event fired');
    window.event_logger.log_clickout(true);
});
