/** GALAXICA **/
export class Partner {

    constructor(app) {
        this.app = app;
        this.script_url = 'https://set.trecky.io/js/track_set.js';
    }

    async init() {
        return new Promise((resolve, reject) => {
            console.log('Partner 2406 init')
            if(this.script_url && this.script_url !== '') {
                const script = $(`script[src='${this.script_url}']`);
                if (script.length > 0 && typeof gnss_sendEvent == "undefined") {
                    $(script).on('load', () => {
                        resolve();
                    });
                } else {
                    $.getScript( this.script_url, function( data, textStatus, jqxhr ) {
                        resolve();
                    });
                }
            }
            else{
                resolve();
            }
        })
    }

    click(){
        console.log('click postback provider: 2406');
        if (typeof gnss_sendEvent !== "undefined") {
            this.sendClickEvent();
        }
        else{
            console.error('No partner script loaded');
        }
    }

    click_dl(){
        console.log('click_dl postback provider: 2406');
        if (typeof gnss_sendEvent !== "undefined") {
            this.sendClickEvent();
        }
        else{
            console.error('No partner script loaded');
        }
    }

    page_view(){
        console.log('page_view postback provider: 2406');
    }

    empty(){
        console.log('empty postback provider: 2406');
        if (typeof gnss_sendEvent !== "undefined") {
            const params = {
                message: 'No sponsored ads'
            };

            try {
                gnss_sendEvent('no_coverage', params);
            }
            catch (error) {
                console.error("Fail to send event 'no_coverage' to partner", error, params);
            }
        }
        else{
            console.error('No partner script loaded');
        }
    }

    getFacebookPixel(){
        switch(this.app.domain){
            case 'fb.beegaloo.com': return '871387545049749';
            case 'fb.gekiroo.com' : return '1182074393209395';
            case 'fb.koquro.com'  : return '1262558414898347';
            case 'fb.frogalix.com': return '934586558543743';
            default: return null;
        }
    }

    getTikTokPixel() {
        switch(this.app.domain){
            case 'tt.beegaloo.com': return 'CVJCM53C77U9Q2MHCDRG';
            case 'tt.gekiroo.com' : return 'CTKMQ9JC77UDE4D3TSA0';
            case 'tt.frogalix.com': return 'CVJCNL3C77U9Q2MHCDVG';
            default: return null;
        }
    }

    getTikTokToken() {
        switch(this.app.domain){
            case 'tt.beegaloo.com': return '02de62f2520b4e915131bacf85c327d4e3f6850f';
            case 'tt.gekiroo.com' : return 'd6c353f1f302cdba6c2cc5ee394d3e9d16003703';
            case 'tt.frogalix.com': return '7e17af4e54e844eaec8e4de387c2e5a34dcf4d10';
            default: return null;
        }
    }

    sendClickEvent() {

        try {
            const params = {
                host: this.app.config.domain,
                path: this.app.request.uri,
                click_id : this.app.data.sub_id,
            };

            gnss_sendEvent('click', params);
        }
        catch (error) {
            console.error("Fail to send event 'click' to partner", error, params);
        }

    }

}
